import { graphql, Link, PageProps } from "gatsby";
import React from "react";
import { Col, Row } from "react-bootstrap";
import SearchResult from "../../components/SearchResult";
import { videoFromNode } from "../../data/videos";

import Layout from "../../layouts/default";

export const Head = () => (
  <title>MandarInput: Comprehensible Input related to language learning</title>
);

export const LanguageLearningPage = ({
  data,
}: PageProps<Queries.LanguageLearningVideosQuery>) => {
  const filteredVideos = data.allVideosYaml.edges.map((node) =>
    videoFromNode(node.node)
  );

  return (
    <Layout>
      <Row>
        <Col sm={12} lg={4}>
          <div className="card">
            <h2>Learn Mandarin with language learning videos</h2>
            <p>
              If you&apos;re learning Mandarin it&apos;s useful to listen to
              videos on topics that you are interested in, and suit your
              purposes for learning.
            </p>
            <p>
              These videos all relate to different approaches and advice for
              language learning. We have{" "}
              <Link to="/">lots of other videos</Link> if you&apos;re interested
              in other topics.
            </p>
            <p>
              If you&apos;re learning Chinese as a second language, you know
              that mastering the language can be a difficult and challenging
              task. But with the right tools and techniques, you can make the
              journey a little easier and more enjoyable. In this list of
              videos, you&apos;ll find tips, advice, and techniques for learning
              Chinese, from expert language learners and teachers. These videos
              will help you identify the best tools to use and provide helpful
              guidance on mastering the language. So whether you&apos;re just
              starting out on your language learning journey or you&apos;re
              looking to take your skills to the next level, these videos will
              help you find the best way to learn Chinese.
            </p>
          </div>
        </Col>
        <Col sm={12} lg={8}>
          {filteredVideos.map(
            (video) =>
              video !== null && (
                <SearchResult
                  key={video.videoId}
                  video={video}
                  viewed={false}
                />
              )
          )}
        </Col>
      </Row>
    </Layout>
  );
};

export default LanguageLearningPage;

export const pageQuery = graphql`
  query LanguageLearningVideos {
    allVideosYaml(filter: { topics: { in: "language-learning" } }) {
      edges {
        node {
          slug
          hsk
          duration
          channel
          topics
          title
          videoId
          subtitles {
            chinese
            english
            pinyin
          }
          speechSpeed
          difficulty
        }
      }
    }
  }
`;
